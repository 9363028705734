// components/Login.js
import React, { useContext, useState } from 'react';
import { Button, Container, TextField, Typography, Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { BaseUrlContext } from '../contexts/BaseUrl';
import { UserContext } from '../contexts/User';
import heatpipersLogo from "../assets/images/heatpipersLogo.webp"
import { theme, useStyles } from '../theme/default'; 


const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const baseUrl = useContext(BaseUrlContext);
  const { setUser } = useContext(UserContext);

  const validationSchema = Yup.object().shape({
    identifier: Yup.string().required('Unique identifier is required'),
  });

  const classes = useStyles();

  // This function sends the identifier to the API and handles the response
  const sendLoginRequest = async (identifier) => {
    try {
      // Replace this with your API endpoint
      const response = await fetch(`${baseUrl}/api/users/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ identifier }),
      });

      const data = await response.json();

      if (data.success) {
        // Redirect to the user dashboard with the user's ID
        return {
          success: true,
          identifier: data.identifier,
        };
      } else {
        return { success: false };
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return { success: false };
    }
  };

  const handleSubmission = async (values, { setSubmitting }) => {
    try {
      const data = await sendLoginRequest(values.identifier);

      if (data.success) {
        // Redirect to the user dashboard with the user's ID
        setUser({
          identifier: data.identifier
        });

        navigate(`/user/${data.identifier}`);
      } else {
        setErrorMessage('Invalid identifier or does not exist'); // Set error message
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container component="main" className={classes.loginContainer} sx={{ marginTop: theme.spacing(3) }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6}>
          <div className={classes.logoContainer}>
            <img src={heatpipersLogo} alt="HeatPipers Logo" className={classes.logoImage} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.formContainer}>
            <Formik
              initialValues={{ identifier: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmission}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form className={classes.form}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="identifier"
                    label="Unique Identifier"
                    error={Boolean(errors.identifier && touched.identifier) || Boolean(errorMessage)}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color={errorMessage ? 'error' : 'primary'}
                    disabled={isSubmitting}
                    className={classes.submitButton}
                  >
                    Connect
                  </Button>
                  {errorMessage && (
                    <Typography color="error" variant="body2" className={classes.errorMessage}>
                      {errorMessage}
                    </Typography>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login