import BruceImage from "../../assets/images/aboutus/Bruce.webp"
import JohnImage from "../../assets/images/aboutus/John.webp"
import LloydImage from "../../assets/images/aboutus/Lloyd.webp"
import RogerImage from "../../assets/images/aboutus/Roger.webp"
import StuartImage from "../../assets/images/aboutus/Stuart.webp"

const teamMembers = [
    {
      name: 'Lloyd Elder',
      position: 'CEO',
      image: LloydImage,
    },
    {
      name: 'Bruce Acton',
      position: 'Board member',
      image: BruceImage,
    },
    {
      name: 'Stuart Shaw',
      position: 'Engineering Lead',
      image: StuartImage,
    },
    {
      name: 'John Logel',
      position: 'Geoscience Specialist',
      image: JohnImage,
    },
    {
      name: 'Roger Booto Tokime',
      position: 'AI Specialist',
      image: RogerImage,
    },
  ];

  export default teamMembers;