import { useState } from "react";
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, Button, styled } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/images/heatpipersLogo.webp";
import { useContext } from "react";
import { UserContext } from "../../contexts/User";
import { theme } from "../../theme/default";

const MobileNavigation = () => {
    const { user, logoutUser } = useContext(UserContext);
    const [openDrawer, setOpenDrawer] = useState(false);

    const StyledNavLink = styled(({ activeClassName, ...props }) => <NavLink {...props} />)({
        textDecoration: 'none',
        '&.active': {
            borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
        },
    });
    

    const StyledButton = styled(Button)(({ theme }) => ({
        fontSize: theme.typography.h6.fontSize.xs,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: theme.typography.h6.fontSize.sm,
        },
        [theme.breakpoints.up('md')]: {
          fontSize: theme.typography.h6.fontSize.md,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: theme.typography.h6.fontSize.lg,
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: theme.typography.h6.fontSize.xl,
        },
      }));
          

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.dark }}>
            <Toolbar style={{ justifyContent: 'center' }}>
                <Link to="/" style={{ marginTop: '10px', textDecoration: 'none', flexGrow: 1 }}>
                    <img src={logo} alt="HeatPipers Logo" height={90} />
                </Link>

                <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => setOpenDrawer(true)}>
                    <MenuIcon />
                </IconButton>
                <Drawer anchor="right" open={openDrawer} onClose={handleCloseDrawer} sx={{ backgroundColor: theme.palette.primary.main }}>
                    <IconButton edge="end" color="inherit" aria-label="close drawer" onClick={handleCloseDrawer} style={{ position: 'absolute', right: 0, top: 0, marginRight: theme.spacing(0.25) }}>
                        <CloseIcon />
                    </IconButton>
                    <List
                        sx={{
                            marginTop: theme.spacing(3)
                        }}
                    >
                        <ListItem onClick={handleCloseDrawer}>
                            <StyledButton color="inherit" component={StyledNavLink} exact to="/" activeClassName="active">Home</StyledButton>
                        </ListItem>
                        <ListItem onClick={handleCloseDrawer}>
                            <StyledButton color="inherit" component={StyledNavLink} to="/about" activeClassName="active">About Us</StyledButton>
                        </ListItem>
                        <ListItem onClick={handleCloseDrawer}>
                            <StyledButton color="inherit" component={StyledNavLink} to="/donate" activeClassName="active">Donate</StyledButton>
                        </ListItem>
                        <ListItem onClick={handleCloseDrawer}>
                            <StyledButton color="inherit" component={StyledNavLink} to="/newsletter" activeClassName="active">Newsletter</StyledButton>
                        </ListItem>
                        {user ? (
                            <>
                                <ListItem onClick={handleCloseDrawer}>
                                    <StyledButton color="inherit" component={StyledNavLink} to={`/user/${user.identifier}`} activeClassName="active">Profile</StyledButton>
                                </ListItem>
                                <ListItem onClick={handleCloseDrawer}>
                                    <StyledButton color="inherit" onClick={logoutUser}>Logout</StyledButton>
                                </ListItem>
                            </>
                        ) : (
                            <ListItem onClick={handleCloseDrawer}>
                                <StyledButton color="inherit" component={StyledNavLink} to="/login" activeClassName="active">Login</StyledButton>
                            </ListItem>
                        )}
                    </List>
                </Drawer>
            </Toolbar>
        </AppBar>
    );
}

export default MobileNavigation;
