import React from 'react';
import { Grid, Typography, Button, useMediaQuery } from '@mui/material';
import { useStyles } from '../theme/default';
import { theme } from '../theme/default';
import { useNavigate } from 'react-router-dom';


const DonationSection = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();

    return (
        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '5%', paddingTop: '5%', minWidth: '70%' }}>
            <Typography 
                variant={!isMobile? "h1" : "h2"} 
                color="secondary" 
                align="center"   
                className={classes.sectionsTitle}
                sx={{ fontWeight: 'bold', paddingBottom: '5%' }}
            >
                Your Legacy, Our Tech
            </Typography>
            <Typography 
                variant={!isMobile? "h2" : "h3"} 
                color={theme.palette.primary.contrastText} 
                align="center"
                
            >
                Envision a world where your grandchildren thrive, thanks to the sustainable power of Mantle Heat Transmission. Each donation is a step closer to that reality. Time is precious; make your impact now
            </Typography>

            <Button
                variant="contained"
                color="secondary"
                sx={{
                    marginTop: !isMobile? "5%": "10%",
                    color: 'white',
                    borderRadius: '40px', 
                    fontSize: !isMobile? theme.typography.body1.fontSize.lg : theme.typography.body1.fontSize.md, 
                }}

                onClick={() => {
                    navigate('/donate');
                }}
            >
                LEGACY GIFT
            </Button> 
        </Grid>
    );
};

export default DonationSection;
