import React from 'react';
import { Grid, Paper, Typography, Container } from '@mui/material';
import { theme, useStyles  } from '../theme/default';

const ConfirmationScreen = ({ handleBack, handleNext }) => {

    const classes = useStyles();

    return (
        <Container maxWidth="sm"> 
            <Grid container justifyContent="center" alignItems="center">
                <Paper
                    elevation={0}
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        padding: theme.spacing(2),
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '5%'
                    }}
                >
                    <Typography variant="h2" color="white"  className={classes.sectionsTitle}>
                        Thank You for Your Generosity!
                    </Typography>
                    <Typography variant="body1" color="white">
                        Your donation has been successfully processed. We are incredibly grateful for your support and generosity.
                        Your contribution is making a significant impact and helping us move closer to our goals.
                        Together, we can make a difference. Thank you once again for your kind-hearted support!
                    </Typography>
                </Paper>
            </Grid>
        </Container>
    );
};

export default ConfirmationScreen;
