import { Typography, useMediaQuery } from "@mui/material";
import valuePropositions from './ValueProposition';
import { theme, useStyles } from "../../theme/default";

const AboutUsSectionText = ({ textToDisplay }) => {
    const classes = useStyles();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const isMedium = useMediaQuery(theme.breakpoints.down("md"));
    const isLarge = useMediaQuery(theme.breakpoints.down("lg"));
    let variant = "h1";

    if (isSmall === true) {
        variant = "h4";
    }
    else if (isMedium) {
        variant = "h2";
    }
    else if (isLarge) {
        variant = "h3";
    }

    return (
        <Typography
            variant={variant}
            className={classes.sectionsDescription}
            paddingTop={theme.spacing(2)}
            paddingBottom={theme.spacing(2)}
        >
            {
                textToDisplay === "" && (
                    <>
                        As an emerging Canadian clean tech startup, HeatPipers Inc. applies established tech for Mantle Heat Transmission (MHT) to redefine the energy sector. Our value is straightforward. 
                    </>
                )
            }
            {
                textToDisplay === "innovation" && (
                    <>
                        {valuePropositions[0].text}
                    </>
                )
            }
            {
                textToDisplay === "market" && (
                    <>
                        {valuePropositions[1].text}
                    </>
                )
            }
            {
                textToDisplay === "calendar" && (
                    <>
                        {valuePropositions[2].text}
                    </>
                )
            }
            {
                textToDisplay === "rollout" && (
                    <>
                        {valuePropositions[3].text}
                    </>
                )
            }
            {
                textToDisplay === "team" && (
                    <>
                        {valuePropositions[4].text}
                    </>
                )
            }
            {
                textToDisplay === "growth" && (
                    <>
                        {valuePropositions[5].text}
                    </>
                )
            }
        </Typography>
    )
};

export default AboutUsSectionText;