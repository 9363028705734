import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SlickCarousel from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { theme, useStyles } from '../theme/default';

const CustomCarousel = ({ slides, settings }) => {
    const classes = useStyles();

    const config = settings? settings : {
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        speed: 500,
        slidesToShow: 3, 
        slidesToScroll: 1,
        initialSlide: 0,
        prevArrow: <div><ArrowBackIcon fontSize="large" color="primary" /></div>,
        nextArrow: <div><ArrowForwardIcon fontSize="large" color="primary" /></div>,
        responsive: [
            {
                breakpoint: theme.breakpoints.values.md,
                settings: {
                    slidesToShow: 2,  // Adjusted for alignment
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: theme.breakpoints.values.sm,
                settings: {
                    slidesToShow: 1,  // Adjusted for alignment
                    slidesToScroll: 1,
                    prevArrow: null,
                    nextArrow: null,
                },
            },
        ],
    };

    return (
        <SlickCarousel {...config}>
            {slides.map((member, index) => (
                <div key={member.name + index} style={{ display: 'flex', justifyContent: 'center' }}> 
                    <Card className={classes.cardContainer}>
                        <CardMedia component="img" className={classes.cardImage} image={member.image} alt={member.name} />
                        <CardContent className={classes.cardContent}>
                            <Typography 
                                variant="h2"
                                fontWeight="bold"
                            >
                                {member.name}
                            </Typography>
                            <Typography 
                                variant="body2"
                                style={{ color: '#555555FF' }}  // added style prop
                            >
                                {member.position}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            ))}
        </SlickCarousel>
    );
};

export default CustomCarousel;
