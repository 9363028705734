import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import { ThemeProvider, Grid, Typography } from '@mui/material';
import Home from './components/Home';
import NewsletterForm from './components/NewsletterForm';
import Login from './components/Login';
import UserDashboard from './components/UserDashboard';
import Admin from './components/Admin';
import { UserContext } from './contexts/User';
import './styles/main.css';
import Footer from './components/Footers/Footer';
import { theme } from './theme/default';
import AboutUs from './Pages/AboutUs';
import NavigationMenu from './components/NavigationMenus/NavigationMenu';
import DonationPage from './Pages/Donation';
import PrivacyPolicy from './Pages/PrivacyPolicy';

const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    if (!user) {
        navigate('/login');
        return null;
    }

    return children;
};

function App() {

    return (
        <ThemeProvider theme={theme}>
            <Router>
                {/* Step 1: Wrap everything inside a container */}
                <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '98.5vh' }}>

                    <Grid item xs={12}>
                        <NavigationMenu />
                    </Grid>

                    {/* Step 3: Make the main content stretch */}
                    <Grid item xs={12} style={{ flex: 1 }}>
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route path="/about" element={<AboutUs />} />
                            <Route path="/admin" element={<Admin />} />
                            <Route path="/donate" element={<DonationPage />} />
                            <Route path="/newsletter" element={<NewsletterForm />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/user/:id" element={<PrivateRoute><UserDashboard /></PrivateRoute>} />
                            <Route path="privacy-policy" element={<PrivacyPolicy />} />
                        </Routes>
                    </Grid>

                    <Grid item xs={12}>
                        <Footer />
                    </Grid>

                    <CookieConsent
                        location="bottom"
                        buttonText="I Understand"
                        cookieName="userConsentCookie"
                        style={{ background: theme.palette.primary.main }}
                        buttonStyle={{ color: "#4e503b" }}
                        expires={150}  // This cookie will expire after 150 days
                    >
                        <Typography
                            variant="h4"
                        >
                            This website uses cookies to enhance the user experience. <a href="/privacy-policy">Learn more</a>
                        </Typography>
                    </CookieConsent>
                    
                </Grid>
            </Router>
        </ThemeProvider>
    );
};

export default App;