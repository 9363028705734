import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../theme/default";
import MobileNavigation from "./MobileNavigation";
import DesktopNavigation from "./DesktopNavigation";
import { useLocation } from "react-router-dom";

const NavigationMenu = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { pathname } = useLocation();

    return (
        <>
            {
                pathname === "/" && !isMobile?
                    <>
                    </>
                :
                <div>
                    {isMobile ? <MobileNavigation /> : <DesktopNavigation />}
                </div> 
            }
        </>
    );
}

export default NavigationMenu;