import React, { useContext, useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Box, Snackbar, CircularProgress  } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { BaseUrlContext } from '../contexts/BaseUrl';
import { theme } from '../theme/default';

const PayPalButton = ({ 
    amount, 
    currency, 
    description, 
    firstName, 
    lastName, 
    email, 
    disabled, 
    setIsPaymentSuccessful,
    isPaymentInProgress,
    setIsPaymentInProgress
}) => {
    const [clientId, setClientId] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [successfulPaymentData, setSuccessfulPaymentData] = useState(null);
    const [failedPaymentError, setFailedPaymentError] = useState(null);

    const baseUrl = useContext(BaseUrlContext);

    useEffect(() => {
        // Fetch the PayPal Client ID from the backend
        if (baseUrl) {
            console.log("Call URL: ", `${baseUrl}/api/payment/paypal/client-id`)
            fetch(`${baseUrl}/api/payment/paypal/client-id`)
                .then(res => res.text())  // Change this temporarily to text
                .then(text => {
                    console.log('Response text:', text);
                    // Attempt to parse it to see if it's valid
                    return JSON.parse(text);
                })
                .then(data => {
                    setClientId(data.clientId);
                })
                .catch(error => {
                    console.error('Failed to fetch PayPal client ID:', error);
                });
        }
    }, [baseUrl]);

    // Register successful payment
    useEffect(() => {
        if (successfulPaymentData) {
            const { orderID } = successfulPaymentData;

            if (!orderID) {
                console.error("Unexpected payment data received:", successfulPaymentData);
                setSnackbarSeverity('error');
                setSnackbarMessage('Payment was made, but the received payment data is incomplete.');
                setShowSnackbar(true);
                return;  // Exit the effect if there's no orderID
            }

            fetch(`${baseUrl}/api/payment/paypal/payment-success`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    transaction: {
                        orderID: orderID,
                        amount: { value: amount, currency_code: currency },
                        description: description,
                    },
                    payerInfo: {
                        email: email,
                        first_name: firstName,
                        last_name: lastName,
                        payer_id: successfulPaymentData.payerID
                    }
                })
            })
                .then(() => {
                    setSuccessfulPaymentData(null);
                    setIsPaymentSuccessful(true);  // Set payment success status to true
                })
                .catch((err) => {
                    console.error("Error saving payment:", err);
                    setSnackbarSeverity('error');
                    setSnackbarMessage('Payment was made, but saving to the database failed.');
                    setShowSnackbar(true);
                });
        }
    }, [successfulPaymentData, baseUrl, amount, currency, description, email, firstName, lastName, setIsPaymentSuccessful]);


    // useEffect for failed payment
    useEffect(() => {
        if (failedPaymentError) {
            fetch(`${baseUrl}/api/payment/paypal/payment-failed`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    paymentInfo: {
                        amount: amount,
                        description: description,
                    },
                    error: failedPaymentError.message
                })
            })
                .then(() => {
                    // The error has been saved successfully, so you might want to reset failedPaymentError.
                    setFailedPaymentError(null);
                })
                .catch((error) => {
                    console.error("Error saving failed payment:", error);
                    setSnackbarSeverity('error');
                    setSnackbarMessage('Payment failed, and saving the error to the database also failed.');
                    setShowSnackbar(true);
                });
        }
    }, [failedPaymentError, baseUrl, amount, description]);

    const handleCreateOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: amount,
                    currency_code: currency
                },
                description: description
            }],
            application_context: {
                shipping_preference: 'NO_SHIPPING' // Set no shipping preference
            },
            payer: {
                name: {
                    given_name: firstName,
                    surname: lastName
                },
                email_address: email
            }
        });
    };

    const handleApprove = async (data, actions) => {
        setIsPaymentInProgress(true);
        setSuccessfulPaymentData(data);
        await actions.order.capture();
        setIsPaymentInProgress(false)
        setIsPaymentSuccessful(true);
        setSnackbarSeverity('success');
        setSnackbarMessage('Payment made successfully!');
        setShowSnackbar(true);
    };

    const handleOnError = (err) => {
        console.error(err);
        setFailedPaymentError(err);
        setSnackbarSeverity('error');
        setSnackbarMessage('Payment failed');
        setShowSnackbar(true);
    };

    const handleSnackbarClose = () => {
        setShowSnackbar(false);
    };

    if (!clientId) {
        // Render a loading spinner while waiting for the client ID
        return (
            <div style={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    marginTop: theme.spacing(5),
                    marginBottom: theme.spacing(5) 
                }}
            >
                <CircularProgress /> 
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Box
            sx={{
                width: 450
            }}
        >
            {
                isPaymentInProgress? (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: theme.spacing(5),
                            marginBottom: theme.spacing(5)
                        }}
                        >
                            <CircularProgress />
                        </div>
                ): null
            }
            <div
                style={{
                  display:  isPaymentInProgress? "none": "block"  
                }}
            >
            <PayPalScriptProvider
                options={{ "client-id": clientId, currency: currency }}
            >
                <PayPalButtons
                    createOrder={handleCreateOrder}
                    onApprove={handleApprove}
                    onError={handleOnError}
                    disabled={disabled}
                />

                <Snackbar open={showSnackbar} autoHideDuration={4000} onClose={handleSnackbarClose}>
                    <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </PayPalScriptProvider>
            </div>
        </Box>
        </div>
    );
};

export default PayPalButton;
