import React, { useEffect, useState } from 'react';
import {
	Typography,
	Grid,
	Box,
	useMediaQuery,
	IconButton,
	Button,
	Dialog,
	DialogContent,
	DialogActions
} from '@mui/material';
import { theme, useStyles } from '../../theme/default';
import ListItemWithVerticalBar from '../ListItemWithVerticalBar';
import CustomCarousel from '../CustomCarousel';
import teamMembers from './TeamMembers';
import AboutUsSectionText from './AboutUsTextToDisplay';
import icons from './AboutUsIcons';
import CloseIcon from '@mui/icons-material/Close';

const About = () => {
	const classes = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const isMedium = useMediaQuery(theme.breakpoints.down("md"));
	const [textToDisplay, setTextToDisplay] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [activeIconIndex, setActiveIconIndex] = useState(0);

	useEffect(() => {
        const timer = setTimeout(() => {
            setTextToDisplay("");
        }, 10000); // 15 seconds

        return () => clearTimeout(timer); // cleanup on unmount or if the value changes
    }, [textToDisplay]);  // Dependency array ensures effect runs when textToDisplay changes

	const handleIconClick = (index) => {
		setActiveIconIndex(index);
		setTextToDisplay(icons[index].name)
		if (isMobile) {
			setIsModalOpen(true);
		}
	};

	const handleNext = () => {
		if (activeIconIndex < icons.length - 1) {
			setActiveIconIndex(prevIndex => prevIndex + 1);
		}
	};

	const handlePrev = () => {
		if (activeIconIndex > 0) {
			setActiveIconIndex(prevIndex => prevIndex - 1);
		}
	};

	return (
		<Grid container direction="row" sx={{ marginBottom: theme.spacing(2) }}>
			<Grid item xs={12}>
				{
					isMobile && (
						<div
							style={{
								marginTop: theme.spacing(2)
							}}
							className={classes.sections}
						>
							<Typography
								variant={"h2"}
								className={classes.sectionsDescription}
								paddingTop={theme.spacing(2)}
								paddingBottom={theme.spacing(2)}
							>
								As an emerging Canadian clean tech startup, HeatPipers Inc. applies established tech for Mantle Heat Transmission (MHT) to redefine the energy sector. Our value is straightforward.
							</Typography>
						</div>
					)
				}
				<Grid container direction="row" padding={theme.spacing(5)} columnSpacing={theme.spacing(2)}>

					{
						!isMobile && (
							<Grid item xs={12} sm={6} md={4} className={classes.sections}>
								<AboutUsSectionText textToDisplay={textToDisplay} />
							</Grid>
						)
					}

					<Grid item xs={12} sm={6} md={8}>
						<ListItemWithVerticalBar
							items={icons}
							barWidth={2}
							cols={isSmall || isMedium? 2 : 3}
							setTextToDisplay={(index) => handleIconClick(index)}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Grid container direction="row" className={classes.sections}>
					<Grid item xs={12}>
						<Typography variant="h1" className={classes.sectionsTitle}>Team</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1" className={classes.sectionsDescription}>
							A very experienced team with skills and pedigrees in Geoscience / Geophysics, Engineering, Chemistry, and
							Artificial Intelligence as well as strong business strategy, market entry, and business development experience.
							The team has also made significant progress in developing external relationships with private industry, and
							academic scholars in specialized domains to re-imagine how this MHT approach can scale. In addition, the team is
							engaged with both Canadian Provincial and Federal government agencies that are ready to support development
							through grant programs that will initially match Industry funds and opportunities for further investment tied to
							technology development hurdles. We have secured an industry backed relationship for access into a 11,000-foot
							observation well where we can execute field validation testing.
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Typography variant="h1" className={classes.sectionsTitle}>Team Members</Typography>
			</Grid>

			<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
				<Box width="90%">
					<CustomCarousel slides={teamMembers} />
				</Box>
			</Grid>

			<Grid item xs={12}>
				<Grid container direction="row" className={classes.sections}>
					<Grid item xs={12}>
						<Typography variant="h1" className={classes.sectionsTitle}>Technology</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1" className={classes.sectionsDescription}>
							HeatPipers has created prototypes adapting a proven technology with the potential to revolutionize the way energy
							is sourced and produced globally. By leveraging MHT, our patent pending solution set will provide a reliable and sustainable source of energy.
							HeatPipers' innovative approach to energy production, once scaled, has the potential to
							significantly reduce the carbon footprint associated with traditional energy production methods. We simplify and
							reduce complexity to access the energy potential from mantle heat sources with this revolutionary technology.
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Dialog
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				fullScreen
				aria-labelledby="about-icon-modal"
				aria-describedby="about-icon-description"
			>
				<DialogContent
					sx={{
						paddingTop: theme.spacing(2),
						paddingRight: theme.spacing(2),
						paddingBottom: theme.spacing(2),
						paddingLeft: theme.spacing(2),
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center', // To vertically center the content
						position: 'relative'     // To position the close icon
					}}
				>
					<IconButton
						sx={{
							position: 'absolute', // To position the close icon
							right: theme.spacing(1),
							top: theme.spacing(1)
						}}
						onClick={() => setIsModalOpen(false)}
					>
						<CloseIcon />
					</IconButton>

					<div
						className={classes.sections}
						style={{
							padding: theme.spacing(2),
							maxWidth: '100%'
						}}
					>
						<AboutUsSectionText textToDisplay={icons[activeIconIndex].name} />
					</div>
				</DialogContent>

				<DialogActions>
					<Button onClick={handlePrev} disabled={activeIconIndex === 0}>
						Previous
					</Button>
					<Button onClick={handleNext} disabled={activeIconIndex === icons.length - 1}>
						Next
					</Button>
				</DialogActions>
			</Dialog>

		</Grid>
	);
};

export default About;
