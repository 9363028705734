import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';

function StepperProgressBar({ activeStep, steps }) {
    return (
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}

export default StepperProgressBar;
