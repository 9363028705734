import React from 'react';
import { Paper, Typography, Button } from '@mui/material';
import { theme, useStyles } from '../theme/default';

const ErrorScreen = ({ handleTryAgain }) => {
    const classes = useStyles();

    return (
        <Paper
            elevation={0}
            sx={{
                padding: theme.spacing(2),
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '5%',
            }}
        >
            <Typography variant="h2" color={theme.palette.error.main} fontWeight="bold" className={classes.sectionsTitle}>
                Oops, Something Went Wrong!
            </Typography>
            <Typography variant="body1" color={theme.palette.error.main} fontWeight="bold">
                There was an error processing your donation. Please try again.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleTryAgain}
                sx={{ marginTop: theme.spacing(2) }}
            >
                Try Again
            </Button>
        </Paper>
    );
};

export default ErrorScreen;
