import React, { useEffect, useState } from 'react';
import { Grid, Box, useTheme } from "@mui/material";
import { keyframes } from "@mui/system";

const ListItemWithVerticalBar = ({ items, setTextToDisplay, cols = 3, barPosition = "", barWidth = 2 }) => {
    const theme = useTheme();

    const pulse = keyframes`
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    `;

    const [animationStarted, setAnimationStarted] = useState(false);

    useEffect(() => {
        setAnimationStarted(true);
    }, []);

    const getBorderStyle = (borderWidth = 2) => {
        switch (barPosition) {
            case "left":
                return { borderLeft: `${borderWidth}px solid`, paddingLeft: theme.spacing(1) };
            case "right":
                return { borderRight: `${borderWidth}px solid`, paddingRight: theme.spacing(1) };
            default:
                return {};
        }
    };

    const handleItemClick = (itemIndex) => {
        setTextToDisplay(itemIndex);
    };

    const handleItemHover = (itemIndex) => {
        setTextToDisplay(itemIndex);
    };

    return (
        <Grid 
            container 
            rowGap={theme.spacing(2)}
            sx={{ 
                marginTop: theme.spacing(3), 
                marginBottom: theme.spacing(3) 
            }}>
            {items.map((item, index) => (
                <Grid 
                    item 
                    xs={12 / cols}
                    key={index}
                    sx={{
                        ...getBorderStyle(barWidth),
                        animation: animationStarted ? `${pulse} 1s ${index * 0.5}s forwards` : "none"
                    }}
                    onClick={() => handleItemClick(index)}
                    onMouseEnter={() => handleItemHover(index)}
                >
                    <Box
                        component="img"
                        src={item.icon}
                        alt={item.name}
                        sx={{
                            width: "100%",
                            height: "auto",
                            cursor: "pointer"
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default ListItemWithVerticalBar;
