import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { TextField, Snackbar, Grid, useTheme, Container } from '@mui/material';
import ButtonWithLoading from './Buttons/ButtonWithLoading';
import { BaseUrlContext } from '../contexts/BaseUrl';

const NewsletterForm = () => {
    const theme = useTheme();

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        corporation: '',
    };

    const [isLoading, setIsLoading] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const baseUrl = useContext(BaseUrlContext);

    const onSubmit = async (values, { resetForm }) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${baseUrl}/api/newsletter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            if (response.ok) {
                setSnackbarSeverity('success');
                setSnackbarMessage('Successfully subscribed!');
                setShowSnackbar(true);
                resetForm();
            } else {
                throw new Error('Failed to subscribe');
            }
        }
        catch (error) {
            console.error('Subscription error:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage('Failed to subscribe');
        }
        finally {
            setIsLoading(false);
            setShowSnackbar(true);
        }
    };

    const validate = (values) => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = 'First name is required';
        }

        if (!values.lastName) {
            errors.lastName = 'Last name is required';
        }

        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }

        return errors;
    };

    const formik = useFormik({
        initialValues,
        initialErrors: {
            firstName: 'Required', // Example initial error for the firstName field
        },
        onSubmit,
        validate,
    });

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const { values, touched, errors, isValid, handleChange, handleBlur, handleSubmit } = formik;

    return (
        <Container
            maxWidth="md"
        >
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} marginTop={theme.spacing(1)}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="firstName"
                            name="firstName"
                            label="First Name"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.firstName && errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="lastName"
                            name="lastName"
                            label="Last Name"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.lastName && errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && errors.email}
                            helperText={touched.email && errors.email}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="corporation"
                            name="corporation"
                            label="Corporation"
                            value={values.corporation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div
                            type="submit"
                        >
                            <ButtonWithLoading
                                isLoading={isLoading}
                                disabled={!isValid}
                                text="Subscribe"
                            />
                        </div>
                    </Grid>
                </Grid>

                <Snackbar
                    open={showSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            </form>
        </Container>
    );
};

export default NewsletterForm;
