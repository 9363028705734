import { Button, CircularProgress } from "@mui/material";

const ButtonWithLoading = (props) => {
    const {
        text,
        isLoading,
        disabled = true
    } = props;

    return (
        <Button
            variant="contained"
            sx={{
                backgroundColor: theme => theme.palette.primary.main,  // Using theme's primary color
                '&:hover': {
                    backgroundColor: theme => theme.palette.primary.dark  // Using theme's primary dark color on hover
                }
            }}
            disabled={isLoading || disabled}
            >
            {isLoading ? <CircularProgress size={24} /> : `${text}`}
        </Button>
    )
};

export default ButtonWithLoading;