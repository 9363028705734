import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Link as MuiLink  } from '@mui/material';
import { Typography } from '@mui/material';

import logo from '../../assets/images/heatpipersLogo.webp';
import { theme } from '../../theme/default';

const HomePageNavigation = () => {

    return (
        <Grid container 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                paddingTop: '5%', 
                minWidth: '70%' 
            }}

            textAlign="center"
            spacing={theme.spacing(2)}
        >
           <Grid
                item
                xs={12}
           >
                <Link 
                    to="/" 
                    style={{ 
                        textDecoration: 'none', 
                        flexGrow: 1, 
                    }}
                >
                    <img 
                        src={logo} 
                        alt="HeatPipers Logo" 
                        height={120} 
                    />
                </Link>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <MuiLink component={Link} to="/about" style={theme.typography.linkStyle}>
                    <Typography variant="h1">ABOUT US</Typography>
                </MuiLink>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <MuiLink component={Link} to="/donate" style={theme.typography.linkStyle}>
                    <Typography variant="h1">DONATE</Typography>
                </MuiLink>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <MuiLink component={Link} to="/newsletter" style={theme.typography.linkStyle}>
                    <Typography variant="h1">NEWSLETTER</Typography>
                </MuiLink>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <MuiLink component={Link} to="/login" style={theme.typography.linkStyle}>
                    <Typography variant="h1">LOGIN</Typography>
                </MuiLink>
            </Grid>
        </Grid>
    );
};

export default HomePageNavigation;
