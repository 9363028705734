import React from 'react';
import { AppBar, Toolbar, Typography } from "@mui/material";
import { theme } from "../../theme/default";
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const { pathname } = useLocation();

    return (
        <>
            {
                pathname !== "/" && (
                    <AppBar 
                        position="static" 
                        color="primary" 
                        sx={{ 
                            bottom: 0,
                            backgroundColor: theme.palette.primary.dark
                        }}>
                        <Toolbar>
                            <Typography variant="body1" color="inherit" style={{ textAlign: 'center', width: '100%' }}>
                                &copy; {new Date().getFullYear()} HeatPipers
                            </Typography>
                        </Toolbar>
                    </AppBar>
                )
            }
        </>
    );
};

export default Footer;