import { createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const fontFamily = 'Montserrat, sans-serif';
const centeredFlex = {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
};

const theme = createTheme({
    typography: {
        fontFamily,
        h1: { fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem", lg: "3.5rem", xl: "4rem" } },
        h2: { fontSize: { xs: "1.8rem", sm: "2.3rem", md: "2.8rem", lg: "3.3rem", xl: "3.8rem" } },
        h3: { fontSize: { xs: "1.6rem", sm: "2.1rem", md: "2.6rem", lg: "3.1rem", xl: "3.6rem" } },
        h4: { fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem", lg: "3rem", xl: "3.5rem" } },
        h5: { fontSize: { xs: "1.3rem", sm: "1.8rem", md: "2.3rem", lg: "2.8rem", xl: "3.3rem" } },
        h6: { fontSize: { xs: "1.1rem", sm: "1.6rem", md: "2.1rem", lg: "2.6rem", xl: "3.1rem" } },
        body1: { fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem", lg: "1.4rem", xl: "1.6rem" } },
        body2: { fontSize: { xs: "0.7rem", sm: "0.9rem", md: "1.1rem", lg: "1.3rem", xl: "1.5rem" } },
        linkStyle: { color: 'white',  textDecoration: 'none', fontWeight: 'bold'},
    },
    palette: {
        primary: {
            main: '#709931',
            light: '#E4F1C2',
            dark: '#728170',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#8DB71F',
            light: '#8EB561',
            dark: '#000000',
            contrastText: '#FFFFFF',
        },
    },
    gradients: {
        top: 'linear-gradient(0deg, #E4F1C2, #709931, #728170, #FFFFFF, #8DB71F, #000000, #8EB561)'
    }
});

const useStyles = makeStyles((theme) => ({
    sectionsTitle: {
        textAlign: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    sections: {
        backgroundColor: theme.palette.primary.main,
        ...centeredFlex,
        height: 'auto',
        color: theme.palette.primary.contrastText
    },
    sectionsDescription: {
        ...centeredFlex,
        color: theme.palette.primary.contrastText,
        maxWidth: '800px',
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    cardContainer: {
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        overflow: 'hidden',
        margin: '10px',
        width: "427px",
        [theme.breakpoints.between('xs', 'md')]: {
            width: '299px',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: '384px',
        },
        
        [theme.breakpoints.up('lg')]: {
            width: '400px', 
        },
    },
    cardImage: {
        height: '500px',
        [theme.breakpoints.between('xs', 'md')]: {
            height: '350px',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            height: '450px',
        },
        width: "100%",
        objectFit: 'fill',
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    loginContainer: {
        marginTop: theme.spacing(3),
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '60px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
    },
    formContainer: {
        display: 'flex',
        marginTop: '40px',
        alignItems: 'center',
        flexDirection: 'column',
        paddingRight: '90px',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            paddingRight: '0',
            marginBottom: '10px',
        },
    },
    logoImage: {
        width: '100%',
        padding: 0,
        marginRight: '30px',
    },
    form: {
        width: '100%',
        fontFamily,
    },
    submitButton: {
        marginTop: '16px',
        backgroundColor: '#8EB561',
    },
    errorMessage: {
        marginTop: '8px',
        fontFamily,
    },
    listItemContainer: {
        marginTop: '30px',
        marginBottom: '30px',
        listStyle: 'none',
        gap: '50px',
    },
    listItem: {
        position: 'relative',
        paddingLeft: '10px',
        fontFamily: 'Montserrat, sans-serif'
    }

    
}));

export { theme, useStyles };
