import React from 'react';
import { Grid, Typography } from '@mui/material';
import { theme, useStyles } from '../theme/default';
import { Container } from '@mui/system';

function PrivacyPolicy() {
    const classes = useStyles();

    return (
        <Container
            maxWidth="xl"
            sx={{
                marginTop: theme.spacing(5)
            }}
        >
            <Grid container spacing={2} className={classes.sections}>
                <Grid item xs={12} className={classes.sectionsTitle}>
                    <Typography variant="h1">Privacy Policy</Typography>
                </Grid>

                <Grid item xs={12} className={classes.sectionsDescription}>
                    <Typography variant="body1">
                        HeatPipers Inc. ("we", "our", or "us") is a Canadian clean tech startup operating the donation and information platform accessible via this website (the "Service"). This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.sectionsDescription}>
                    <Typography variant="h2">Information Collection And Use</Typography>
                    <Typography variant="body1">
                        We collect several different types of information for various purposes including to process donations, potentially process refunds, monitor and analyze web traffic, and improve our Service to you. The types of information collected may include: Transaction Data, Usage Data, and Cookies Data.
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.sectionsDescription}>
                    <Typography variant="h2">Use of Data</Typography>
                    <Typography variant="body1">
                        HeatPipers Inc. uses the collected data for various purposes such as to provide and maintain the Service, process donations, process refunds when necessary, notify you about changes to our Service, allow you to participate in interactive features of our Service when you choose to do so, provide customer support, monitor the usage of the Service, and gather analysis or valuable information so that we can improve the Service.
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.sectionsDescription}>
                    <Typography variant="h2">Retention of Data</Typography>
                    <Typography variant="body1">
                        HeatPipers Inc. will retain your Personal Data for a period of 5 years, after which it will be archived. This retention period enables us to process refunds when necessary, comply with legal obligations, and analyze web traffic to improve our Service.
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.sectionsDescription}>
                    <Typography variant="h2">Your Rights</Typography>
                    <Typography variant="body1">
                        You have the right to access, update, or delete the personal information we have on you. You can contact us at any time to learn more about or exercise these rights.
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.sectionsDescription}>
                    <Typography variant="h2">Contact Us</Typography>
                    <Typography variant="body1">
                        If you have any questions about this Privacy Policy, please contact us:
                        <br />
                        Email: info@heatpipers.com
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

export default PrivacyPolicy;
