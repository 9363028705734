import React from 'react';
import Button from '@mui/material/Button';

function BackButton({ onClick, disabled }) {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      Back
    </Button>
  );
}

export default BackButton;
