const valuePropositions = [
    {
        text: "HeatPipers transforms geothermal tech and processes, merging breakthrough innovation and MHT's efficiency in one groundbreaking approach."
    },
    {
        text: "In seven years, HeatPipers will generate 2000 GW, curbing 3-5 gigatons of carbon by challenging fossil fuel rivals."
    },
    {
        text: "HeatPipers' mature tech is scalable, set for a pilot in 8 months, and full deployment in 12 to 18 months."
    },
    {
        text: "HeatPipers leverages existing assets, ensuring swift, cost-effective tech deployment and broader industry acceptance."
    },
    {
        text: "A top-tier team drives HeatPipers, adeptly steering tech, sales, and strategies to achieve every company objective."
    },
    {
        text: "Our 4-year pro forma, backed by thorough analysis, targets the 35T+ energy market, aiming for a 5B+ SAM."
    }
];

export default valuePropositions;