import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, Alert } from '@mui/material';
import { BaseUrlContext } from '../contexts/BaseUrl';

const UserDashboard = () => {
  const { id } = useParams();

  const [pdfFile, setPdfFile] = useState(null);
  const [name, setName] = useState('');
  const [error, setError] = useState(null);

  const BASE_URL = useContext(BaseUrlContext);

  // Fetch user's data from API
  const fetchUser = useCallback(async () => {
    try {
      const response = await fetch(`${BASE_URL}/users/${id}`);
      const userData = await response.json();
      if (userData.success) {
        setName(userData.user.name);
      } else {
        throw new Error(userData.message || 'Error fetching user data');
      }
    } catch (error) {
      setError(error.message);
      console.error('Error fetching user:', error);
    }
  }, [BASE_URL, id]);

  // Fetch PDF from API
  const fetchPDF = useCallback(async () => {
    try {
      const response = await fetch(`${BASE_URL}/users/${id}/pdf`);
      const pdfBlob = await response.blob();
      return URL.createObjectURL(pdfBlob);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  }, [BASE_URL, id]);

  useEffect(() => {
    (async () => {
      await fetchUser();
      if (!error) {
        const file = await fetchPDF();
        setPdfFile(file);
      }
    })();
  }, [fetchUser, fetchPDF, setPdfFile, error]);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: (theme) => theme.spacing(3),
        width: '100%',
        height: '100vh',
        maxWidth: 'none',
        padding: 0,
      }}
    >
      {error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
          <Typography variant="h5" sx={{ marginBottom: '16px' }}>Welcome {name}</Typography>

          {name && pdfFile && (
            <iframe
              src={pdfFile}
              width="100%"
              height="100%"
              type="application/pdf"
              style={{ border: 'none' }}
              title="PDF Viewer"
            >
              <p>
                It appears your web browser doesn't support iframes. You can{" "}
                <a href={pdfFile} target="_blank" rel="noopener noreferrer">
                  view the document here.
                </a>
              </p>
            </iframe>
          )}
        </>
      )}
    </Container>
  );
};

export default UserDashboard;
