import { AppBar, Toolbar, Button, Menu, MenuItem, styled } from "@mui/material";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/images/heatpipersLogo.webp";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/User";
import { theme } from "../../theme/default";

const DesktopNavigation = () => {
    const { user, logoutUser } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const StyledNavLink = styled(({ activeClassName, ...props }) => <NavLink {...props} />)({
        textDecoration: 'none',
        '&.active': {
            borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
        },
    });    

    const StyledButton = styled(Button)(({ theme }) => ({
        fontSize: theme.typography.body1.fontSize.xs,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: theme.typography.body1.fontSize.sm,
        },
        [theme.breakpoints.up('md')]: {
          fontSize: theme.typography.body1.fontSize.md,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: theme.typography.body1.fontSize.lg,
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: theme.typography.body1.fontSize.xl,
        },
      }));
      

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleMenuClose();
        logoutUser();
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.dark }}>
            <Toolbar style={{ marginRight: '50px' }}>
                <Link to="/" style={{ marginTop: '10px', textDecoration: 'none', flexGrow: 1 }}>
                    <img src={logo} alt="HeatPipers Logo" height={90} />
                </Link>

                <StyledButton color="inherit" component={StyledNavLink} to="/about" activeClassName="active">About Us</StyledButton>
                <StyledButton color="inherit" component={StyledNavLink} to="/donate" activeClassName="active">Donate</StyledButton>
                <StyledButton color="inherit" component={StyledNavLink} to="/newsletter" activeClassName="active">Newsletter</StyledButton>
                {user ? (
                    <>
                        <StyledButton color="inherit" onClick={handleMenuOpen}>Profile</StyledButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem>
                                <StyledButton color="inherit" component={StyledNavLink} to={`/user/${user.identifier}`}>Dashboard</StyledButton>
                            </MenuItem>
                            <MenuItem>
                                <StyledButton color="inherit" onClick={handleLogout}>Logout</StyledButton>
                            </MenuItem>
                        </Menu>
                    </>
                ) : (
                    <StyledButton color="inherit" component={StyledNavLink} to="/login" activeClassName="active">Login</StyledButton>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default DesktopNavigation;
