import React, { useState, useEffect, useCallback, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField, IconButton, Stack, Typography, Modal, Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { useSnackbar } from 'notistack';
import { Alert } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { BaseUrlContext } from '../contexts/BaseUrl';

const AdminPage = () => {
  const [rows, setRows] = useState([]);
  const [editRowsState, setEditRowsState] = useState({});
  const [selectedFiles, setSelectedFiles] = useState({});
  const [selectedFileName, setSelectedFileName] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [nextId, setNextId] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [newUserName, setNewUserName] = useState('');
  const [isUserNameValid, setIsUserNameValid] = useState(true);
  const [isFileSelected, setIsFileSelected] = useState(true);

  const baseUrl = useContext(BaseUrlContext);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 130, editable: true },
    { field: 'identifier', headerName: 'Identifier', width: 130, editable: false },
    {
      field: 'pdfPath',
      headerName: 'PDF',
      width: 200,
      renderCell: (params) => (
        <div>
          {editRowsState[params.id] ? (
            <input
              type="file"
              accept=".pdf"
              onChange={(e) => handleFileInputChange(e, params.row)}
            />
          ) : (
            <Typography>{params.row.pdfPath}</Typography>
          )}
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 160,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <IconButton color="primary" onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton color="success" onClick={() => handleSave(params.row)}>
            <SaveIcon />
          </IconButton>
          <IconButton color="error" onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    fetch(`${baseUrl}/api/users/all`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setRows(data.users);
          setNextId(data.users.length + 1);
        } else {
          throw new Error(data.message || 'Failed to fetch users');
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      });
  }, [enqueueSnackbar, baseUrl]);

  const handleEdit = (row) => {
    setEditRowsState((state) => {
      const newState = { ...state };
      newState[row.id] = row;
      return newState;
    });
  };

  const handleSave = async (row) => {
    if (!selectedFiles[row.id]) {
      enqueueSnackbar('Please select a PDF file', { variant: 'error' });
      return;
    }

    const newUser = { name: row.name, identifier: row.identifier };

    try {
      const response = await fetch(`${baseUrl}/api/users/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newUser),
      });
      const data = await response.json();

      if (!data.success) {
        throw new Error(data.message || 'Failed to create user');
      }

      const formData = new FormData();
      formData.append('file', selectedFiles[row.id]);

      const pdfResponse = await fetch(`${baseUrl}/api/users/upload/${data.identifier}`, {
        method: 'POST',
        body: formData,
      });
      const pdfData = await pdfResponse.json();

      if (!pdfData.success) {
        throw new Error(pdfData.message || 'Failed to upload PDF file');
      }

      enqueueSnackbar('User created and PDF file uploaded successfully', {
        variant: 'success',
      });

      // Instead of updating single row, fetch updated data
      fetch(`${baseUrl}/api/users/all`)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setRows(data.users);
            setNextId(data.users.length + 1);
          } else {
            throw new Error(data.message || 'Failed to fetch users');
          }
        })
        .catch((error) => {
          enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
        });

      setEditRowsState((state) => {
        const newState = { ...state };
        delete newState[row.id];
        return newState;
      });
    } catch (error) {
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
    }
  };

  const handleDelete = (id) => {
    fetch(`${baseUrl}/api/users/delete/${id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          enqueueSnackbar('User deleted successfully', { variant: 'success' });
          setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        } else {
          enqueueSnackbar(`Error: ${data.message}`, { variant: 'error' });
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      });
  };

  const handleAdd = () => {
    setOpenModal(true);
  };

  const handleDropzoneChange = useCallback((files) => {
    if (files && files.length > 0) {
      const newId = nextId.toString();
      const newUser = {
        id: newId,
        name: newUserName,
        identifier: Math.random().toString(36).substring(2, 15),
        pdfPath: files[0],
      };
      setSelectedFiles((prevSelectedFiles) => ({ ...prevSelectedFiles, [newId]: files[0] }));
      setSelectedFileName(files[0].name);
      setNewUser(newUser);
    }
  }, [nextId, newUserName]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDropzoneChange,
    accept: 'application/pdf',
  });

  const handleModalClose = () => {
    setOpenModal(false);
    if (newUser) {
      setNewUser(null);
      setSelectedFileName("");
      setNewUserName('');
      setIsUserNameValid(true);
      setIsFileSelected(true);
    }
  };

  const handleSaveUser = async () => {
    if (!newUser || !newUserName || !selectedFiles[newUser.id]) {
      enqueueSnackbar('Please enter a user name and select a PDF file before saving', { variant: 'error' });
      return;
    }

    newUser.name = newUserName;
    await handleSave(newUser);
    handleModalClose();
  };

  const handleSaveAll = async () => {
    for (const row of Object.values(editRowsState)) {
      if (!row.name || !row.identifier || !selectedFiles[row.id]) {
        enqueueSnackbar('Please fill in all fields and select a PDF file before saving', {
          variant: 'error',
        });
        return;
      }
      await handleSave(row);
    }
    setEditRowsState({});
    enqueueSnackbar('All changes saved successfully', { variant: 'success' });
  };


  const handleFileInputChange = (event, row) => {
    const file = event.target.files[0];
    setSelectedFiles((prevSelectedFiles) => ({ ...prevSelectedFiles, [row.id]: file }));
    setSelectedFileName(file.name);
    setIsFileSelected(true);
  };

  const handleUserNameChange = (event) => {
    const value = event.target.value;
    setNewUserName(value);
    setIsUserNameValid(value.trim() !== '');
  };

  return (
    <div>
      <Stack direction="row" spacing={1}>
        <IconButton color="success" onClick={handleAdd}>
          <AddIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={handleSaveAll}
          disabled={Object.values(editRowsState).some(
            (row) => !row.name || !row.identifier || !selectedFiles[row.id]
          )}
        >
          <SyncAltIcon />
        </IconButton>
      </Stack>
      <Modal open={openModal} onClose={handleModalClose}>
        <Box
          sx={{
            maxWidth: 600,
            margin: 'auto',
            mt: '15vh', // Adjust the vertical positioning as needed
            p: 2,
            bgcolor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Add New User</Typography>
          <TextField
            label="User Name"
            value={newUserName}
            onChange={handleUserNameChange}
            margin="normal"
            fullWidth
            error={!isUserNameValid}
            helperText={!isUserNameValid && 'User Name cannot be empty'}
          />
          <div
            {...getRootProps()}
            style={{
              border: `2px dashed ${isFileSelected ? '#000' : 'red'}`,
              padding: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag 'n' drop some files here, or click to select files</p>
            )}
          </div>
          {selectedFileName && (
            <Typography sx={{ color: 'green' }}>
              Selected file: {selectedFileName}
            </Typography>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveUser}
              disabled={!newUserName || !selectedFiles[newUser?.id]}
            >
              Save
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={handleModalClose}
              sx={{ marginLeft: 1 }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        editMode="cell"
        onEditCellChangeCommitted={(e) => {
          const id = e.id;
          const field = e.field;
          const value = e.props.value;
          setEditRowsState((state) => ({
            ...state,
            [id]: {
              ...state[id],
              [field]: value,
            },
          }));
        }}
        components={{
          noRowsOverlay: () => (
            <Alert severity="info">No users found. Add a user using the Add button.</Alert>
          ),
        }}
        getCellClassName={(params) =>
          params.field === 'pdfPath' && !params.value ? 'MuiDataGrid-cellError' : ''
        }
        getCellEditProps={({ params }) => ({
          onBlur: (event) => {
            if (event.currentTarget.nodeName === 'TD') {
              const input = event.currentTarget.querySelector('input');
              if (input && input.value !== params.value) {
                const id = params.id;
                const field = params.field;
                const value = input.value;
                setEditRowsState((state) => ({
                  ...state,
                  [id]: {
                    ...state[id],
                    [field]: value,
                  },
                }));
              }
            }
          },
        })}
        componentsProps={{
          toolbar: { sx: { marginBottom: 2 } },
          footer: { sx: { marginTop: 2 } },
        }}
      />
    </div>
  );
};

export default AdminPage;
