import { Box, Typography } from '@mui/material';
import { theme } from '../../theme/default';

const HomePageFooter = () => {

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={5} 
            sx={{
                color: theme.palette.common.white,
                backgroundColor: `rgba(114, 129, 112, 0.33)`
            }}
        >
            <Typography variant="body2">
                &copy; {new Date().getFullYear()} HeatPipers
            </Typography>
        </Box>
    );
};

export default HomePageFooter;
