import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Grid, Paper, Container } from '@mui/material';
import { theme } from '../theme/default';
import PayPalButton from '../components/PayPalButton';
import StepperProgressBar from '../components/StepperProgressBar';
import ConfirmationScreen from '../components/ConfirmationScreen';
import ErrorScreen from '../components/ErrorScreen';
import NextButton from '../components/Buttons/NextButton';
import BackButton from '../components/Buttons/BackButton';
import DonationForm from '../components/Forms/Donation';

// Validation schema using Yup
const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    amount: Yup.number()
        .typeError('Donation amount must be a number')
        .positive('Donation amount must be positive')
        .required('Donation amount is required')
        .test('decimal-places', 'Please round the donation amount to the nearest cent', (value) => {
            if (value) {
                const decimalPlaces = value.toString().split('.')[1];
                if (decimalPlaces && decimalPlaces.length > 2) {
                    return false;
                }
            }
            return true;
        }),
});

function DonationPage({ paperBackgroundVisible = true }) {

    const handleBack = () => {
        // Go back to the previous step
        setActiveStep(activeStep - 1);
    };

    const handleNext = () => {
        // Proceed to the next step
        setActiveStep(activeStep + 1);
    };

    // steps for the stepper
    const steps = ['Donation Details', 'Payment', 'Confirmation'];

    // active step states
    const [activeStep, setActiveStep] = useState(0);

    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);

    const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);

    const [paymentFailed, setPaymentFailed] = useState(false);

    const areAllFieldsFilled = () => {
        const { values, isValid } = formik;
        const test = values.firstName.length !== 0 && values.lastName.length !== 0 && values.email.length !== 0 && values.amount > 0 && isValid;
        return test;//values.firstName.length === 0 && values.lastName.length === 0 && values.email.length === 0 && values.amount.length === 0 && isValid;
    };

    // Form submission and validation using Formik
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            amount: '',
            paymentMethod: '',
            currency: 'USD',
            description: "Donation to HeatPipers Inc."
        },
        initialErrors: {
            firstName: 'Required', // Example initial error for the firstName field
        },
        validationSchema,
    });

    const { values, isValid } = formik;

    return (
        <Container
            maxWidth="md"
            sx={{
                marginTop: theme.spacing(5)
            }}
        >
            <form>
                <Paper
                    elevation={0}
                    sx={{
                        backgroundColor: paperBackgroundVisible ? undefined : 'transparent',
                    }}
                >
                    <Grid container spacing={2} sx={{ width: "100%", marginTop: theme.spacing(1) }}>
                        <Grid
                            item
                            xs={12}
                        >
                            <StepperProgressBar activeStep={activeStep} steps={steps} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            marginTop={theme.spacing(3)}
                            marginBottom={theme.spacing(3)}
                        >
                            {activeStep === 0 && (
                                // Step 1: Donation Details
                                <DonationForm
                                    {...formik}
                                />
                            )}

                            {activeStep === 1 && (
                                // Step 2: Payment (PayPal)
                                <>
                                    {paymentFailed ? (
                                        // Render the ErrorScreen component when payment has failed
                                        <ErrorScreen handleTryAgain={() => setPaymentFailed(false)} />
                                    ) : (
                                        // Render the PayPalButton component when payment hasn't failed
                                        <PayPalButton
                                            disabled={!isValid || isPaymentSuccessful}
                                            text={'Donate'}
                                            {...values}
                                            amount={values.amount}
                                            currency={values.currency}
                                            description={values.description}
                                            setIsPaymentSuccessful={() => {
                                                setIsPaymentSuccessful(true);
                                                setActiveStep(activeStep + 1); // Transition to the next step
                                            }}
                                            isPaymentInProgress={isPaymentInProgress}
                                            setIsPaymentInProgress={setIsPaymentInProgress}
                                        />
                                    )}
                                </>
                            )}

                            {activeStep === 2 && (
                                // Step 3: Confirmation
                                <ConfirmationScreen />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            marginBottom={theme.spacing(3)}
                        >
                            <Grid
                                container
                            >
                                <Grid item xs={6}>
                                    <BackButton
                                        onClick={handleBack}
                                        disabled={activeStep === 0 || isPaymentSuccessful || paymentFailed || isPaymentInProgress}
                                    />
                                </Grid>

                                <Grid item xs={6} align="right">
                                    <NextButton
                                        onClick={handleNext}
                                        isLink={activeStep === 2}
                                        disabled={activeStep === 2? false : activeStep === 1? !isPaymentSuccessful: !areAllFieldsFilled()}
                                        text={activeStep === 2? "Back to Home Page": null}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Container>
    );
}

export default DonationPage;
