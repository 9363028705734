import React, { useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/main.css";
import { BaseUrlContext } from "./contexts/BaseUrl";
import { UserContext } from "./contexts/User";
import env from "react-dotenv";

let BASE_URL = "";
if (process.env.NODE_ENV !== "production") {
  BASE_URL = `${env.HP_COMM_HTTP}${env.HP_COMM_HOST}:${env.HP_COMM_PORT}`;
} else {
  BASE_URL = `${env.HP_API_URL}`;
}

const Main = () => {
  const [user, setUser] = useState(null);

  const logoutUser = () => {
    setUser(null);
  };

  return (
    <BaseUrlContext.Provider value={BASE_URL}>
      <UserContext.Provider value={{ user, setUser, logoutUser }}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </UserContext.Provider>
    </BaseUrlContext.Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById("root"));
