import innovation from "../../assets/images/aboutus/icons/innovation.svg";
import market from "../../assets/images/aboutus/icons/market.svg";
import calendar from "../../assets/images/aboutus/icons/calendar.svg";
import rollout from "../../assets/images/aboutus/icons/rollout.svg";
import team from "../../assets/images/aboutus/icons/team.svg";
import growth from "../../assets/images/aboutus/icons/growth.svg";

const icons = [
    {
        name: "innovation",
        icon: innovation
    },
    {
        name: "market",
        icon: market
    },
    {
        name: "calendar",
        icon: calendar
    },
    {
        name: "rollout",
        icon: rollout
    },
    {
        name: "team",
        icon: team
    },
    {
        name: "growth",
        icon: growth
    }
];

export default icons