import React from 'react';
import { Grid, Box, useMediaQuery } from '@mui/material';
import { theme } from '../theme/default';
import HomePageNavigation from './NavigationMenus/HomePageNavigation';
import DonationSection from './DonationSection';
import HomePageFooter from './Footers/HomePageFooter';

const Home = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"  // Center the items
            style={{ minHeight: '100vh' }}
            sx={{
                backgroundImage: {
                    xs: `url(${require("../assets/images/banners/homePage_xsmall.webp")})`,
                    sm: `url(${require("../assets/images/banners/homePage_small.webp")})`,
                    md: `url(${require("../assets/images/banners/homePage_medium.webp")})`,
                    lg: `url(${require("../assets/images/banners/homePage_large.webp")})`,
                    xl: `url(${require("../assets/images/banners/homePage_xlarge.webp")})`
                },
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            {/* Main content */}
            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        padding: "5%",
                        backgroundPosition: 'center'
                    }}
                >
                    <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            height: 450,
                        }}
                    >
                        {
                            !isMobile && (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <HomePageNavigation />
                                </Grid>
                            )
                        }

                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <DonationSection />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

            {/* Footer */}
            <Grid item xs={12} style={{ flexShrink: 0, marginTop: 'auto' }}>
                <HomePageFooter />
            </Grid>
        </Grid>
    );
};

export default Home;
