import { Grid, TextField } from "@mui/material";
import { theme } from "../../theme/default";

const DonationForm = ({ values, touched, errors, handleChange, handleBlur}) => {
    return (
        <Grid
            container
            rowSpacing={theme.spacing(2)}
        >
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    sx={{ backgroundColor: "rgba(255,255,255,0.6)" }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                    sx={{ backgroundColor: "rgba(255,255,255,0.6)" }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{ backgroundColor: "rgba(255,255,255,0.6)" }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="amount"
                    name="amount"
                    label="Donation Amount (USD)"
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.amount && !!errors.amount}
                    helperText={touched.amount && errors.amount}
                    sx={{ backgroundColor: "rgba(255,255,255,0.6)" }}
                    inputProps={{
                        step: '1.00',
                        min: '1',
                        type: 'number',
                    }}
                />
            </Grid>
        </Grid>
    )
};

export default DonationForm;