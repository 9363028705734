import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

function NextButton({ onClick, disabled, text, isLink = false, path="/" }) {

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(path);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={isLink? handleNavigate: onClick}
      disabled={disabled}
    >
      {text? text: "Next"}
    </Button>
  );
}

export default NextButton;
